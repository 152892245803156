import { Spinner } from '@chakra-ui/react';
import React, { PropsWithChildren, Suspense } from 'react';

interface SuspenseHandlerProps {
  spinnerColor?: 'white' | 'black';
}

export default function SuspenseHandler({
  children,
  spinnerColor = 'black'
}: PropsWithChildren<SuspenseHandlerProps>) {
  return (
    <Suspense
      fallback={
        <div
          style={{
            width: '100%',
            minHeight: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spinner color={spinnerColor} />
        </div>
      }
    >
      {children}
    </Suspense>
  );
}
