import { Box } from '@chakra-ui/react';
import Header from 'components/Header';
import { NavigationIds } from 'components/Header/navigation';
import { HeaderContainer } from 'components/Header/styled';
import HeaderIntl from 'components/HeaderIntl';
import Notification from 'components/Notification';
import { useNotification } from 'hooks/useNotification';
import noop from 'lodash/noop';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation } from 'react-router';
import { useScrollPosition } from './useScrollPosition';

interface ProviderProps {
  intlHeader?: boolean;
}

export const HeaderComponentContext = React.createContext<{
  initialHeight: number;
  scrolledHeight: number;
  stickyHeader: boolean;
  transparentHeader: boolean;
  cookieBannerDisplay: boolean;
  subNavigationOpenId?: NavigationIds;
  isShowSearch: boolean;
  hideHeader: boolean;
  bgColor?: string; // header background color
  setCookieBannerDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  hideShadow: () => void;
  setStickyHeader: (value: boolean) => void;
  setTransparentHeader: (value: boolean) => void;
  setHideHeader: (value: boolean) => void;
  setSubNavigationOpenId: (value?: NavigationIds) => void;
  setIsShowSearch: (value: boolean) => void;
  setBgColor: (value: string | undefined) => void; // use to set bg color
}>({
  initialHeight: 0,
  scrolledHeight: 0,
  stickyHeader: true,
  transparentHeader: false,
  cookieBannerDisplay: false,
  subNavigationOpenId: undefined,
  isShowSearch: false,
  hideHeader: false,
  setCookieBannerDisplay: noop,
  hideShadow: noop,
  setStickyHeader: noop,
  setTransparentHeader: noop,
  setHideHeader: noop,
  setSubNavigationOpenId: noop,
  setIsShowSearch: noop,
  setBgColor: noop
});

export const HeaderProvider: React.FC<ProviderProps> = ({
  children,
  intlHeader
}) => {
  const [initialHeight, setInitialHeight] = useState(0);
  const [scrolledHeight, setScrolledHeight] = useState(0);
  const [hideShadow, setHideShadow] = useState(false);
  const [transparent, setTransparent] = useState(false);
  const [sticky, setSticky] = useState(true);
  const [hideHeader, setHideHeader] = useState(false);
  const [cookieBannerDisplay, setCookieBannerDisplay] = useState(false); // Check whether cookies banner display on ui.
  const [subNavigationOpenId, setSubNavigationOpenId] = useState<
    NavigationIds
  >();
  const [isShowSearch, setIsShowSearch] = useState(false);
  const [bgColor, setBgColor] = useState<string>();

  const location = useLocation();
  const { pathname } = location;
  const headerRef = useRef<HTMLDivElement>(null);
  const { id } = useNotification();
  const { scroll80 } = useScrollPosition();
  const handleHideShadow = useCallback(() => setHideShadow(true), []);
  const handleSetSticky = useCallback((value: boolean) => setSticky(value), []);
  const handleSetTransparent = useCallback(
    (value: boolean) => setTransparent(value),
    []
  );

  const handleHeaderScrolled = () => {
    setTimeout(() => {
      setScrolledHeight(headerRef.current?.clientHeight || 0);
      setInitialHeight(headerRef.current?.clientHeight || 0);
    }, 300);
  };

  const handleHideHeader = useCallback(
    (value: boolean) => setHideHeader(value),
    []
  );

  useEffect(() => {
    const initialHeightSetJob = setTimeout(
      () => setInitialHeight(headerRef.current?.clientHeight || 0),
      10
    );

    return () => {
      clearTimeout(initialHeightSetJob);
    };
  }, [id, pathname]);

  return (
    <HeaderComponentContext.Provider
      value={{
        initialHeight,
        scrolledHeight,
        stickyHeader: sticky,
        transparentHeader: transparent && !scroll80 && !subNavigationOpenId,
        hideShadow: handleHideShadow,
        cookieBannerDisplay,
        subNavigationOpenId,
        isShowSearch,
        hideHeader,
        bgColor,
        setCookieBannerDisplay,
        setStickyHeader: handleSetSticky,
        setTransparentHeader: handleSetTransparent,
        setHideHeader: handleHideHeader,
        setSubNavigationOpenId,
        setIsShowSearch,
        setBgColor
      }}
    >
      {!hideHeader && (
        <>
          <HeaderContainer sticky={sticky} id="site-header" ref={headerRef}>
            <Notification />

            {intlHeader != null && (
              <HeaderIntl
                hideShadow={hideShadow}
                onScrolled={handleHeaderScrolled}
              />
            )}

            {intlHeader == null && (
              <Header
                hideShadow={hideShadow}
                onScrolled={handleHeaderScrolled}
              />
            )}
          </HeaderContainer>
          {!transparent && <Box height={`${initialHeight}px`} />}
        </>
      )}

      {children}
    </HeaderComponentContext.Provider>
  );
};

export const useHeader = () => useContext(HeaderComponentContext);
