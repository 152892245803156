import { useBreakpointValue } from '@chakra-ui/react';

/* 
note: useBreakpointValue will return undefined on the first render. 
so, we need to handle that case by checking the window width.
*/

const useIsMobileView = (overrideBreakPointValue?: Record<string, boolean>) => {
  const isMobileView = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
    ...(overrideBreakPointValue ?? {})
  });

  if (isMobileView === undefined) return window.innerWidth < 768;

  return isMobileView;
};

export default useIsMobileView;
