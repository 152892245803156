import {
  getAnalytics,
  getGoogleAnalyticsClientId,
  logEvent,
  setUserProperties
} from 'firebase/analytics';
import isPrerenderCrawler from 'helpers/isPrerenderCrawler';
import { CHALLENGES_TYPE } from 'services/groups/challenges-common-types';
import { LooseObject } from 'services/interface';
import firebase from '.';
import {
  ANALYTICS_SERVICE_EVENT_NAMES,
  GA_CLICK_EVENT_NAMES
} from './ga_events';

const analytics = isPrerenderCrawler() ? undefined : getAnalytics(firebase);

export const getUserPsuedoId = async () => {
  if (!analytics) return null;

  const analytics1 = getAnalytics(firebase);
  const timeout = new Promise<null>(resolve =>
    setTimeout(() => resolve(null), 1000)
  );

  const clientId = await Promise.race([
    getGoogleAnalyticsClientId(analytics1),
    timeout
  ]);

  return clientId;
};

interface LogGAEventParams extends LooseObject {
  event_name: string; // page_view, click, view etc.
  page_name?: string;
  previous_page_name?: string;
  experiment_id?: string;
  variation_id?: string;
  promo_code?: string;
  service_event_name?: ANALYTICS_SERVICE_EVENT_NAMES;
}

export const logGAEvent = (eventDetails: LogGAEventParams): void => {
  if (analytics) {
    logEvent(analytics, eventDetails.event_name, {
      ...eventDetails,
      user_agent: navigator?.userAgent
    });
  }
};

interface LogPageViewEventParams extends LooseObject {
  page_name: string; // current page path
  previous_page_name: string; // previous page path
}

export const logPageViewEvent = (
  eventDetails: LogPageViewEventParams
): void => {
  if (analytics) {
    logEvent(analytics, 'page_view', {
      ...eventDetails,
      event_name: 'page_view',
      name: 'page_view',
      user_agent: navigator?.userAgent
    });
  }
};

export interface LogGAClickEventParams extends LooseObject {
  click_event_name?: GA_CLICK_EVENT_NAMES;
  library_item_id?: string;
  library_item_type?: string;
  live_event_id?: string;
  course_id?: string;
  time_id?: string;
  topic_id?: string;
  link?: string;
  challenge_banner_id?: string;
  tracking_object_type?:
    | 'LIBRARY_ITEMS'
    | 'EVENT'
    | 'GROUP'
    | 'SHOP_PRODUCT'
    | 'PLAYLIST'
    | 'PUBLISHER';
  item_type?: 'COURSES';
  challenge_template_type?: CHALLENGES_TYPE;
  promo_code?: string;
}

export interface AnalyticsUserProperties {
  userId?: string;
  isSubscriber?: boolean;
}

export const setAnalyticsUserProperties = ({
  userId,
  isSubscriber
}: AnalyticsUserProperties) => {
  if (analytics) {
    setUserProperties(analytics, {
      is_subscriber: isSubscriber,
      user_id: userId
    });
  }
};

export default analytics;
