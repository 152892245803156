import { extendTheme } from '@chakra-ui/react';
import Button from './components/Button';
import CloseButton from './components/CloseButton';
import Heading from './components/Heading';
import Text from './components/Text';
import colors from './foundations/colors';
import fonts from './foundations/fonts';

const theme = extendTheme({
  colors,
  fonts,
  components: {
    CloseButton,
    Button,
    Heading,
    Text
  }
});

export default theme;
