import { useGrowthBook } from '@growthbook/growthbook-react';
import { isProduction } from 'lib/environment';
import { AuthState, useFirebaseAuth } from './useFirebaseAuth';

const useABTest = () => {
  const { authState } = useFirebaseAuth();
  const growthbook = useGrowthBook();

  // E12
  const getCTAUnderHeroImageVariant = () => {
    if (authState === AuthState.AUTHORIZED || isProduction) {
      return 'control';
    }

    return growthbook?.getFeatureValue(
      'website_cta_under_hero_image',
      'control'
    );
  };
  const ctaUnderHeroImageVariant = getCTAUnderHeroImageVariant();

  const getStartedVariantValue = () => {
    if (authState === AuthState.AUTHORIZED) {
      return 'control';
    }

    return growthbook?.getFeatureValue(
      'website_get_started_copy_changes',
      'control'
    );
  };

  const getStartedVariant = getStartedVariantValue();

  const headerCopyChangesVariant = growthbook?.getFeatureValue(
    'website_header_copy_changes',
    'control'
  );

  // E17 Hero Image Changes

  const heroImageChangesVariant = growthbook?.getFeatureValue(
    'website_hero_image',
    'control'
  );

  const onboardingVariant = growthbook?.getFeatureValue(
    'website_onboarding_quiz',
    'control'
  );

  return {
    ctaUnderHeroImageVariant,
    getStartedVariant,
    headerCopyChangesVariant,
    heroImageChangesVariant,
    onboardingVariant
  };
};

export default useABTest;
